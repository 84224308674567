export interface OidcParams {
  /**
   * Client id
   */
  readonly clientId: string;
  /**
   * Redirect URI
   */
  readonly redirectUri: string;

  /**
   * Response type
   */
  readonly responseType: string;

  /**
   * Scope
   */
  readonly scope: string;

  /**
   * State
   */
  readonly state: string;

  /**
   * Nonce
   */
  readonly nonce: string;

  /**
   * Response mode determines how ID token is sent back to RP
   * Possible values are:
   * * query - via query string parameters
   * * fragment - via URL fragment
   * Default is `query`
   */
  readonly responseMode?: string;

  /**
   * Determines whether to produce ID token using Sentry key and format or not
   * If false Midway ID token is produced (default).
   */
  readonly useSentryKey?: boolean;

  /**
   * Extra parameters to be added to the URL.
   */
  readonly extraParams: Map<string, string>;
}

type UrlType = "authorization" | "sentry_authorization" | "oob_login" | "other";

/**
 * Result of the url parsing
 */
export interface ParsedUrl {
  /**
   * URL type
   */
  readonly type: UrlType;

  /**
   * OIDC parameters
   */
  readonly oidcParams?: OidcParams;
}

/**
 * Url type from path
 */
function urlType(path: string): UrlType {
  if (path === "/SSO/redirect") {
    return "authorization";
  } else if (path === "/sentry/SSO/redirect") {
    return "sentry_authorization";
  } else if (path === "/oob/login") {
    return "oob_login";
  } else {
    return "other";
  }
}

function paramsToMap(params: URLSearchParams): Map<string, string> {
  const result = new Map<string, string>();
  params.forEach((value, key) => {
    result.set(key, value);
  });
  return result;
}

/**
 * Parses a URL and extracts the path and query parameters.
 */
export function parseMidwayUrl(params: { params: URLSearchParams; path: string }): ParsedUrl {
  const type = urlType(params.path);
  let oidcParams: OidcParams | undefined;
  if (type === "authorization" || type === "sentry_authorization") {
    const q = params.params;
    const knownKeys = [
      "client_id",
      "redirect_uri",
      "response_type",
      "scope",
      "state",
      "nonce",
      "response_mode",
      "use_sentry_key",
    ];
    const knownParams = {
      clientId: q.get("client_id") ?? "",
      redirectUri: q.get("redirect_uri") ?? "",
      responseType: q.get("response_type") ?? "",
      scope: q.get("scope") ?? "",
      state: q.get("state") ?? "",
      nonce: q.get("nonce") ?? "",
      responseMode: q.get("response_mode") ?? undefined,
      useSentryKey: q.get("use_sentry_key") === "1",
    };
    for (const key of knownKeys) {
      q.delete(key);
    }
    oidcParams = {
      ...knownParams,
      extraParams: paramsToMap(q),
    };
  }
  return {
    type,
    oidcParams: oidcParams,
  };
}

/**
 * Convert OidcParams to URLSearchParams.
 */
export function oidcParamsToUrlParams(params: OidcParams): URLSearchParams {
  const result = new URLSearchParams();
  result.set("redirect_uri", params.redirectUri);
  result.set("client_id", params.clientId);
  result.set("scope", params.scope);
  result.set("state", params.state);
  result.set("nonce", params.nonce);
  result.set("response_type", params.responseType);
  if (params.responseMode) {
    result.set("response_mode", params.responseMode);
  }
  if (params.useSentryKey) {
    result.set("use_sentry_key", "1");
  }
  params.extraParams.forEach((value, key) => {
    result.set(key, value);
  });
  return result;
}
