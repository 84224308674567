/**
 * Interface with the AEA Android App.
 */
import * as I18n from "i18n-js";
import { BiometricApi, BiometricAuthResult } from "misc/biometric_api";

interface AndroidAeaAuth {
  postMessage(message: string): string;
}

declare global {
  interface Window {
    AeaAndroidAuth?: AndroidAeaAuth;
  }
}

class AndroidAeaApp implements BiometricApi {
  isApiAvailable(): boolean {
    return !!window.AeaAndroidAuth;
  }

  /**
   * Initiates authentication via the AEA Android App.
   * Returns a promise that resolves to the JWT claims.
   */
  performBiometricAuthentication(): Promise<BiometricAuthResult> {
    return new Promise<BiometricAuthResult>(function (resolve, reject) {
      const aeaAndroidAuth = window.AeaAndroidAuth;
      if (!aeaAndroidAuth) {
        reject(I18n.t("js.fingerprint_authentication_is_not_available_on_this_device"));
        return;
      }

      const message = {
        version: "0.1.0",
        payload: {
          request: {
            endpoint: "aea.android.authenticateUser",
            kwargs: {
              authMethod: "com.amazon.aea.android.authMethod.biometric",
              authType: "JWT",
            },
          },
          callback: {
            object: null,
            function: null,
          },
        },
      };
      const json_message = JSON.stringify(message);
      const data_json = aeaAndroidAuth.postMessage(json_message);
      const data = JSON.parse(data_json).data;
      if (data.error || !data.payload || !data.payload.data) {
        reject(data.error.title || I18n.t("js.touchid_authentication_failed"));
        return;
      }
      const claim = data.payload.data as string;
      resolve({ claim });
    });
  }

  isBiometricAvailable(): Promise<boolean> {
    return Promise.resolve(this.isApiAvailable());
  }
}

export const androidAeaApp = new AndroidAeaApp();
