/**
 * This pack is imported into old UI pages at the end of the page (after page is fully loaded)
 */
import { switchToNewUI } from "misc/utils";

/**
 * Add event handler for button which switches to new UI
 */
const newUISwitchButton = document.querySelector("#switch-to-new-ui-button");
if (newUISwitchButton) {
  (newUISwitchButton as HTMLButtonElement).onclick = (e) => {
    switchToNewUI();
  };
}
